import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../interfaces/user";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import Select from "react-select";
import { fetchRegions } from "../../redux/reducers/lan";
import { fetchLegalRequest } from "../../redux/reducers/legal-request";
import LegalRequest from "../../interfaces/legal_request";

const LegalRequestListing: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const legalRequestItems = useSelector(
        (state: any) => state.legalRequest.items
    );
    const userData: User = useSelector((state: any) => state.user.userData);
    const regions: string[] = useSelector((state: any) => state.lan.regions);

    const navigateToAdd = () => {
        navigate("/add-legal-request");
    };

    useEffect(() => {
        dispatch(fetchLegalRequest({}));
        dispatch(fetchRegions());
    }, [dispatch]);

    const handleRegionChange = (values: any) => {
        let regions = values.map((value: any) => value.value);
        regions = regions.join(",");

        dispatch(fetchLegalRequest({ regions: regions }));
    };

    return (
        <>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    <input
                        type="text"
                        style={{
                            padding: "0.375rem 0.75rem",
                            fontSize: "1rem",
                            lineHeight: 1.5,
                            border: "1px solid #ced4da",
                            borderRadius: "0.25rem",
                            color: "#495057",
                            backgroundColor: "#fff",
                            boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
                        }}
                        placeholder="Search LAN No"
                        onChange={(event: any) => {
                            dispatch(
                                fetchLegalRequest({
                                    search: event.target.value,
                                })
                            );
                        }}
                    />
                    {userData?.role?.rights.includes("add_legal_request") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("view_legal_request") && (
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(fetchLegalRequest({ export: true }));
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div
                    className="table-responsive text-nowrap"
                    style={{ overflowX: "auto" }}
                >
                    <table className="table table-striped freezeHead">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>LAN No</th>
                                <th>Borrower Name</th>
                                <th>Borrower Address</th>
                                <th>Borrower Contact Number</th>
                                <th>Co-Borrower/ Guarantor Name</th>
                                <th>Co-Borrower/ Guarantor Address</th>
                                <th>Co-Borrower/ Guarantor Contact Number</th>
                                <th>Vehicle Availability</th>
                                <th>Legal Request</th>
                                <th>Priority as per OPN POS</th>
                                <th>Remarks</th>
                                <th>Created At</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {legalRequestItems.map(
                                (legalRequest: LegalRequest, index: number) => {
                                    return (
                                        <tr key={legalRequest._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/view-legal-request/${legalRequest._id}`}
                                                >
                                                    {legalRequest.lan_no}
                                                </Link>
                                            </td>
                                            <td>
                                                {legalRequest.borrower_name}
                                            </td>
                                            <td>
                                                {legalRequest.borrower_address}
                                            </td>
                                            <td>
                                                {legalRequest.borrower_contact}
                                            </td>
                                            <td>
                                                {legalRequest.gurantor_name}
                                            </td>
                                            <td>
                                                {legalRequest.gurantor_address}
                                            </td>
                                            <td>
                                                {legalRequest.gurantor_contact}
                                            </td>
                                            <td>
                                                {
                                                    legalRequest.vehicle_availability
                                                }
                                            </td>
                                            <td>
                                                {legalRequest.legal_request}
                                            </td>
                                            <td>{legalRequest.priority}</td>
                                            <td>{legalRequest.remarks}</td>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    legalRequest.createdAt
                                                )}
                                            </td>
                                            <td>
                                                {legalRequest.createdBy?.name}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default LegalRequestListing;
