import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import AutocompleteField from "../../components/AutoCompleteField";
import { useDispatch, useSelector } from "react-redux";
import { searchLan, selectLanItems, setLan } from "../../redux/reducers/lan";
import Lan from "../../interfaces/lan";
import MaskedInput from "react-input-mask";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
    formatDateToYYYYMMDD,
} from "../../helpers/date";
import { fetchProfile } from "../../redux/reducers/user";
import { User } from "../../interfaces/user";
import { Modal, Button } from "react-bootstrap";
import RepoRelease from "../../interfaces/repo_release";
import {
    addRepoRelease,
    fetchRepoReleaseLogs,
    fetchSingleRepoRelease,
    setRepoRelease,
    updateRepoRelease,
} from "../../redux/reducers/repo-release";
import { RepoReleaseLogs } from "../../interfaces/repo_release_logs";

const AddRepoRelease: React.FC = () => {
    const navigate = useNavigate();
    const lanItems = useSelector(selectLanItems);
    const userData: User = useSelector((state: any) => state.user.userData);
    const repoRelease = useSelector(
        (state: any) => state.repoRelease.repoRelease
    );
    const [modalIsOpen, setIsOpen] = useState(false);

    const [lanNo, setLanNo] = useState("");
    const [receiptDate, setReceiptDate] = useState("");
    const [unoReceiptNo, setUnoReceiptNo] = useState("");
    const [receiptAmount, setReceiptAmount] = useState("");
    const [totalReceiptAmount, setTotalReceiptAmount] = useState(0);

    const [paymentDetailsArr, setPaymentDetailsArr] = useState<
        PaymentDetails[]
    >([]);

    const dispatch: Dispatch<any> = useDispatch();

    const { repoId } = useParams();

    const [initialFormValues, setInitialFormValues] = useState({
        normal_repo_expenses: 0,
        additional_repo_expenses: 0,
        total_repo_expenses: 0,
    });

    let logs: RepoReleaseLogs[] = useSelector(
        (state: any) => state.repoRelease.logs
    );

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    useEffect(() => {
        if (repoId) {
            dispatch(
                fetchSingleRepoRelease({
                    _id: repoId,
                })
            );
            dispatch(fetchRepoReleaseLogs(repoId as string));
        } else {
            dispatch(setRepoRelease({}));
        }
    }, [repoId, dispatch]);

    useEffect(() => {
        const values: any = {} as RepoRelease;
        Object.keys(repoRelease as keyof RepoRelease).forEach((key) => {
            values[key] = repoRelease[key];
        });
        values.lrn_date = formatDateToYYYYMMDD(values.lrn_date);
        setPaymentDetailsArr(values.payment_details || []);
        setInitialFormValues(values);
    }, [repoRelease]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    useEffect(() => {
        const newTotal = paymentDetailsArr.reduce(
            (acc, item) => acc + item.receipt_amount,
            0
        );
        setTotalReceiptAmount(newTotal);
    }, [paymentDetailsArr]);

    const onSubmit = (values: RepoRelease) => {
        values.payment_details = paymentDetailsArr;
        values.total_receipt_amount = totalReceiptAmount;
        if (repoId) {
            dispatch(updateRepoRelease(values));
        } else {
            dispatch(addRepoRelease(values));
        }
        navigate("/repo-release-listing");
    };

    const validate = (values: RepoRelease) => {
        const errors: Partial<Record<keyof RepoRelease, string>> = {};

        const requiredFields: (keyof RepoRelease)[] = [
            "lan_no",
            "penal_charges",
            "repo_charges",
            "legal_charges",
            "emi_overdue_outstanding",
            "emi_overdue_collection",
            "emi_overdue_waiver",
            "current_month_emi_outstanding",
            "current_month_emi_collection",
            "current_month_emi_waiver",
            "other_charges_outstanding",
            "other_charges_collection",
            "other_charges_waiver",
            "repo_charges_outstanding",
            "repo_charges_collection",
            "repo_charges_waiver",
            "advance_emi_collection",
            "future_pos_outstanding",
            "future_pos_collection",
            "future_pos_waiver",
            "total_outstanding",
            "total_collection",
            "total_waiver",
            "challan_copy",
            "approval_nrm",
            "legal_charges_outstanding",
            "legal_charges_collection",
            "legal_charges_waiver",
            "cbc_arrear_outstanding",
            "cbc_arrear_collection",
            "cbc_arrear_waiver",
            "other_charges_1_outstanding",
            "other_charges_1_collection",
            "other_charges_1_waiver",
            "other_charges_2_outstanding",
            "other_charges_2_collection",
            "other_charges_2_waiver",
            "other_charges_3_outstanding",
            "other_charges_3_collection",
        ];

        requiredFields.forEach((field) => {
            if (values[field] === undefined || values[field] === null) {
                errors[field] = "Required";
            }
        });

        const collectionValidation = [
            ["emi_overdue_collection", "emi_overdue_outstanding"],
            ["current_month_emi_collection", "current_month_emi_outstanding"],
            ["other_charges_collection", "other_charges_outstanding"],
            ["repo_charges_collection", "repo_charges_outstanding"],
            ["advance_emi_collection", "advance_emi_outstanding"],
            ["future_pos_collection", "future_pos_outstanding"],
            ["legal_charges_collection", "legal_charges_outstanding"],
            ["cbc_arrear_collection", "cbc_arrear_outstanding"],
            ["other_charges_1_collection", "other_charges_1_outstanding"],
            ["other_charges_2_collection", "other_charges_2_outstanding"],
            ["other_charges_3_collection", "other_charges_3_outstanding"],
        ] as const;

        collectionValidation.forEach(([collection, outstanding]) => {
            const collectionValue = values[collection];
            const outstandingValue = values[outstanding];

            if (
                collectionValue !== undefined &&
                outstandingValue !== undefined &&
                collectionValue > outstandingValue
            ) {
                errors[collection] =
                    "Should be less than or equal to outstanding amount";
            }
        });

        // console.log(errors);
        return errors;
    };

    const setFormValue = (form: any, key: string, value: any) => {
        form.change(key, value);
    };
    const handleLanInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
                type: "repo-release",
            })
        );
    };

    const onLanSelected = (form: any, value: Lan) => {
        setFormValue(form, "customer_name", value.customer_name);
        setFormValue(form, "lan_no", value.lan_no);
        setFormValue(form, "zone", value.zone);
        setFormValue(form, "region", value.region);
        setFormValue(form, "branch", value.branch);
        setFormValue(form, "portfolio", value.portfolio);
        setFormValue(form, "pos", value.pos);
        setFormValue(form, "other_charges", value.other_charges);
        setFormValue(form, "rc_no", value.rc_no);
        setFormValue(form, "chassis_no", value.chassis_no);
        setFormValue(form, "engine_number", value.engine_number);
        setFormValue(form, "make", value.make);
        setFormValue(form, "model", value.model);
        setFormValue(form, "mfg_year", value.mfg_year);
        setFormValue(form, "revised_focus_band", value.revised_focus_band);
        setFormValue(form, "repo_charges", value.repo_charges || 0);
        setFormValue(form, "penal_charges", value.penal_charges || 0);
        setFormValue(form, "legal_charges", value.legal_charges || 0);
        setFormValue(
            form,
            "total_emi_collectable",
            value.total_emi_collectable
        );
        setFormValue(form, "as_on_date_dpd", value.as_on_date_dpd);
        setFormValue(
            form,
            "total_gross_collection",
            value.total_gross_collection
        );
        setFormValue(form, "sec_17_9", value.sec_17_9 != null ? "Yes" : "No");
        setFormValue(form, "sec_138", value.sec_138?.length > 0 ? "Yes" : "No");
        setFormValue(
            form,
            "lrn_date",
            value.lrn_date ? formatDateToYYYYMMDD(value.lrn_date) : ""
        );
        setFormValue(
            form,
            "arbitration_status",
            value.arbitration_status?.length > 0 ? "Yes" : "No"
        );
    };

    const handleAmountChange = (form: any, key: string, value: number) => {
        form.change(key, value);

        /****************EMI Overdue Calculations******************/
        const emiOverdueOustanding =
            parseInt(form.getState().values.emi_overdue_outstanding) || 0;
        const emiOverdueCollection =
            parseInt(form.getState().values.emi_overdue_collection) || 0;
        const emiOverdueWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((emiOverdueOustanding - emiOverdueCollection) /
                        emiOverdueOustanding) *
                        100
                ).toFixed(2)
            ) || 0;

        form.change("emi_overdue_waiver", emiOverdueWaiver || 0);
        /****************EMI Overdue Calculations******************/

        /****************Current Month EMI Calculations******************/
        const currentMonthEmiOutstanding =
            parseInt(form.getState().values.current_month_emi_outstanding) || 0;
        const currentMonthEmiCollection =
            parseInt(form.getState().values.current_month_emi_collection) || 0;
        const currentMonthEmiWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((currentMonthEmiOutstanding - currentMonthEmiCollection) /
                        currentMonthEmiOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("current_month_emi_waiver", currentMonthEmiWaiver || 0);
        /****************Current Month EMI Calculations******************/

        /****************Other charges Calculations******************/
        const otherChargesOutstanding =
            parseInt(form.getState().values.other_charges_outstanding) || 0;
        const otherChargesCollection =
            parseInt(form.getState().values.other_charges_collection) || 0;
        const otherChargesWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((otherChargesOutstanding - otherChargesCollection) /
                        otherChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("other_charges_waiver", otherChargesWaiver || 0);
        /****************Other charges Calculations******************/

        /****************Advance EMI Calculations******************/
        const advanceEmiCollection =
            parseInt(form.getState().values.advance_emi_collection) || 0;
        /****************Advance EMI Calculations******************/

        /****************Repo Charges Calculations******************/
        const repoChargesOutstanding =
            parseInt(form.getState().values.repo_charges_outstanding) || 0;
        const repoChargesCollection =
            parseInt(form.getState().values.repo_charges_collection) || 0;
        const repoChargesWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((repoChargesOutstanding - repoChargesCollection) /
                        repoChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("repo_charges_waiver", repoChargesWaiver || 0);
        /****************Repo Charges Calculations******************/

        /****************Legal Charges Calculations******************/
        const legalChargesOutstanding =
            parseInt(form.getState().values.legal_charges_outstanding) || 0;
        const legalChargesCollection =
            parseInt(form.getState().values.legal_charges_collection) || 0;
        const legalChargesWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((legalChargesOutstanding - legalChargesCollection) /
                        legalChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("legal_charges_waiver", legalChargesWaiver || 0);
        /****************Legal Charges Calculations******************/

        /****************CBC Arrear Calculations******************/
        const cbcArrearOutstanding =
            parseInt(form.getState().values.cbc_arrear_outstanding) || 0;
        const cbcArrearCollection =
            parseInt(form.getState().values.cbc_arrear_collection) || 0;
        const cbcArrearWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((cbcArrearOutstanding - cbcArrearCollection) /
                        cbcArrearOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("cbc_arrear_waiver", cbcArrearWaiver || 0);
        /****************CBC Arrear Calculations******************/

        /****************Other Charges 1 Calculations******************/
        const otherCharges1Outstanding =
            parseInt(form.getState().values.other_charges_1_outstanding) || 0;
        const otherCharges1Collection =
            parseInt(form.getState().values.other_charges_1_collection) || 0;
        const otherCharges1Waiver =
            parseFloat(
                Math.max(
                    0,
                    ((otherCharges1Outstanding - otherCharges1Collection) /
                        otherCharges1Outstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("other_charges_1_waiver", otherCharges1Waiver || 0);
        /****************Other Charges 1 Calculations******************/

        /****************Other Charges 2 Calculations******************/
        const otherCharges2Outstanding =
            parseInt(form.getState().values.other_charges_2_outstanding) || 0;
        const otherCharges2Collection =
            parseInt(form.getState().values.other_charges_2_collection) || 0;
        const otherCharges2Waiver =
            parseFloat(
                Math.max(
                    0,
                    ((otherCharges2Outstanding - otherCharges2Collection) /
                        otherCharges2Outstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("other_charges_2_waiver", otherCharges2Waiver || 0);
        /****************Other Charges 2 Calculations******************/

        /****************Other Charges 3 Calculations******************/
        const otherCharges3Outstanding =
            parseInt(form.getState().values.other_charges_3_outstanding) || 0;
        const otherCharges3Collection =
            parseInt(form.getState().values.other_charges_3_collection) || 0;
        const otherCharges3Waiver =
            parseFloat(
                Math.max(
                    0,
                    ((otherCharges3Outstanding - otherCharges3Collection) /
                        otherCharges3Outstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("other_charges_3_waiver", otherCharges3Waiver || 0);
        /****************Other Charges 3 Calculations******************/

        /****************Future POS Calculations******************/
        const futurePosOutstanding =
            parseInt(form.getState().values.future_pos_outstanding) || 0;
        const futurePosCollection =
            parseInt(form.getState().values.future_pos_collection) || 0;
        const futurePosWaiver =
            parseFloat(
                Math.max(
                    0,
                    ((futurePosOutstanding - futurePosCollection) /
                        futurePosOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change("future_pos_waiver", futurePosWaiver || 0);
        /****************Future POS Calculations******************/

        const totalOutstandingValue =
            emiOverdueOustanding +
            currentMonthEmiOutstanding +
            otherChargesOutstanding +
            repoChargesOutstanding +
            legalChargesOutstanding +
            cbcArrearOutstanding +
            otherCharges1Outstanding +
            otherCharges2Outstanding +
            otherCharges3Outstanding;

        const totalCollectionValue =
            emiOverdueCollection +
            currentMonthEmiCollection +
            otherChargesCollection +
            repoChargesCollection +
            legalChargesCollection +
            cbcArrearCollection +
            otherCharges1Collection +
            otherCharges2Collection +
            otherCharges3Collection;

        let totalWaiverValue =
            emiOverdueWaiver +
            currentMonthEmiWaiver +
            otherChargesWaiver +
            repoChargesWaiver +
            legalChargesWaiver +
            cbcArrearWaiver +
            otherCharges1Waiver +
            otherCharges2Waiver +
            otherCharges3Waiver;

        totalWaiverValue = totalWaiverValue / 9;

        totalWaiverValue = isNaN(totalWaiverValue) ? 0 : totalWaiverValue;

        form.change(
            "total_outstanding",
            isNaN(totalOutstandingValue) ? 0 : totalOutstandingValue
        );

        form.change(
            "total_collection",
            isNaN(totalCollectionValue) ? 0 : totalCollectionValue
        );

        form.change("total_waiver", totalWaiverValue.toFixed(2));
    };

    const currentDate = new Date().toISOString().split("T")[0];

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Payment Receipt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-2">
                            <div className="col mb-3">
                                <label
                                    htmlFor="nameBasic"
                                    className="form-label"
                                >
                                    LAN No
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    id="nameBasic"
                                    className="form-control"
                                    placeholder="Enter LAN No"
                                    value={lanNo}
                                    onChange={(event) => {
                                        setLanNo(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="col mb-3">
                                <label
                                    htmlFor="nameBasic"
                                    className="form-label"
                                >
                                    Receipt date
                                </label>
                                <input
                                    type="date"
                                    id="multicol-birthdate"
                                    className="form-control dob-picker"
                                    placeholder="YYYY-MM-DD"
                                    value={receiptDate}
                                    max={currentDate}
                                    onChange={(event) => {
                                        setReceiptDate(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row g-2">
                            <div className="col mb-0">
                                <label
                                    htmlFor="emailBasic"
                                    className="form-label"
                                >
                                    UNO Receipt No
                                </label>
                                <input
                                    type="text"
                                    id="nameBasic"
                                    className="form-control"
                                    placeholder="Enter UNO Receipt No"
                                    value={unoReceiptNo}
                                    onChange={(event) => {
                                        setUnoReceiptNo(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="col mb-0">
                                <label
                                    htmlFor="emailBasic"
                                    className="form-label"
                                >
                                    Receipt amount
                                </label>
                                <div className="input-group">
                                    <span className="input-group-text">₹</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={receiptAmount}
                                        onChange={(event) => {
                                            setReceiptAmount(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                const details: PaymentDetails = {
                                    lan_no: lanNo,
                                    receipt_amount: parseInt(receiptAmount),
                                    receipt_date: new Date(receiptDate),
                                    uno_receipt_no: unoReceiptNo,
                                };
                                setPaymentDetailsArr([
                                    ...paymentDetailsArr,
                                    details,
                                ]);
                                setLanNo("");
                                setReceiptAmount("");
                                setReceiptDate("");
                                setUnoReceiptNo("");
                                setIsOpen(false);
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">Add New Entry</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialFormValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={
                                                                repoId == null
                                                                    ? false
                                                                    : true
                                                            }
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_lan_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search LAN No"
                                                            aria-label="Search LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            }
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {
                                                                        item.lan_no
                                                                    }
                                                                </div>
                                                            )}
                                                            getKey={(
                                                                item: any
                                                            ) => item._id}
                                                            onInputChange={
                                                                handleLanInputChange
                                                            }
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onLanSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Customer Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="customer_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Customer Name"
                                                            aria-label="Customer Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="customer_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Zone
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="zone"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Zone"
                                                            aria-label="Zone"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="zone"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Region
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map-pin"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="region"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Region"
                                                            aria-label="Region"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="region"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Branch
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-smart-home"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="branch"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Branch"
                                                            aria-label="Branch"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="branch"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Portfolio
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="portfolio"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Portfolio"
                                                            aria-label="Portfolio"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="portfolio"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    POS
                                                </label>
                                                <div className="col-sm-2">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="pos"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="POS"
                                                            aria-label="POS"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="pos"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Penal Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="penal_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="penal_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Repo Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="repo_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="repo_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Legal Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="legal_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="legal_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Other Charges
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            initialValue={0}
                                                            disabled={true}
                                                            component="input"
                                                            name="other_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Other Charges"
                                                            aria-label="Other Charges"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="other_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="rc_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="RC No"
                                                            aria-label="RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="rc_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Chassis No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-file-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="chassis_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Chassis No"
                                                            aria-label="Chassis No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="chassis_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Engine Number
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-engine"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="engine_number"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Engine Number"
                                                            aria-label="Engine Number"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="engine_number"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Make
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-layers-intersect"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="make"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Make"
                                                            aria-label="Make"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="make"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Model
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-star"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="model"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Model"
                                                            aria-label="Model"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="model"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Year of Manufacturing
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>

                                                        <Field
                                                            component="input"
                                                            name="mfg_year"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Year of Manufacturing"
                                                            aria-label="Year of Manufacturing"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            {({ input }) => (
                                                                <MaskedInput
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    className="form-control"
                                                                    {...input}
                                                                    mask="9999"
                                                                    placeholder="YYYY"
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Revised Focus Band
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="revised_focus_band"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Revised Focus Band"
                                                            aria-label="Revised Focus Band"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total EMI Collectable
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_emi_collectable"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total EMI Collectable"
                                                            aria-label="Total EMI Collectable"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    As on date DPD
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="as_on_date_dpd"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="As on date DPD"
                                                            aria-label="As on date DPD"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total Gross Collection
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_gross_collection"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total Gross Collection"
                                                            aria-label="Total Gross Collection"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    LRN Date
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lrn_date"
                                                            type="date"
                                                            id="multicol-birthdate"
                                                            className="form-control dob-picker"
                                                            placeholder="YYYY-MM-DD"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lrn_date"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 17/ Sec 9 available:
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_17_9"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 138 available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_138"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Any litigation against case
                                                    available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="arbitration_status"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-5">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Repo Release Ticketing
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="row row-gap-4">
                                                        <div className="col-md-12">
                                                            <div className="table-responsive text-nowrap">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Outstanding
                                                                                <br />
                                                                                Header
                                                                            </th>
                                                                            <th>
                                                                                Total
                                                                                <br />
                                                                                Outstanding
                                                                            </th>
                                                                            <th>
                                                                                Collection
                                                                            </th>
                                                                            <th>
                                                                                Waiver
                                                                                %
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                EMI
                                                                                Overdue
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    component="input"
                                                                                    type="number"
                                                                                    name="emi_overdue_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "emi_overdue_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    component="input"
                                                                                    type="number"
                                                                                    name="emi_overdue_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "emi_overdue_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="emi_overdue_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="emi_overdue_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "emi_overdue_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Current
                                                                                Month
                                                                                EMI
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="current_month_emi_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "current_month_emi_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="current_month_emi_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "current_month_emi_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="current_month_emi_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="current_month_emi_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "current_month_emi_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Other
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Repo
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="repo_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="repo_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="repo_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="repo_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="repo_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Legal
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="legal_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="legal_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="legal_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="legal_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="legal_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                CBC
                                                                                Arrear
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="cbc_arrear_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cbc_arrear_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="cbc_arrear_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="cbc_arrear_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cbc_arrear_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="cbc_arrear_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="cbc_arrear_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cbc_arrear_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Other
                                                                                Charges
                                                                                1
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_1_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_1_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_1_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_1_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_1_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_1_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_1_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_1_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Other
                                                                                Charges
                                                                                2
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_2_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_2_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_2_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_2_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_2_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_2_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_2_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_2_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Other
                                                                                Charges
                                                                                3
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_3_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_3_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_3_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_3_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_3_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_3_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_3_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_3_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Total
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="total_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="total_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="total_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Advance
                                                                                EMI
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="advance_emi_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "advance_emi_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="advance_emi_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="advance_emi_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "advance_emi_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="advance_emi_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="advance_emi_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "advance_emi_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Future
                                                                                POS
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="future_pos_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_pos_outstanding",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="future_pos_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_pos_collection",
                                                                                            parseInt(
                                                                                                event
                                                                                                    .target
                                                                                                    .value
                                                                                            )
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="future_pos_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="future_pos_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_pos_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        {repoId && (
                                                            <div className="col-sm-8">
                                                                <div className="row row-gap-4">
                                                                    <div className="col-md-6">
                                                                        <a
                                                                            href={
                                                                                repoRelease.challan_copy as string
                                                                            }
                                                                            target="_blank"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        Receipt
                                                                                        or
                                                                                        Deposit
                                                                                        Challan
                                                                                        copy
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <a
                                                                            href={
                                                                                repoRelease.approval_nrm as string
                                                                            }
                                                                            target="_blank"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        Repo
                                                                                        Release
                                                                                        Approval
                                                                                        (NRM)
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Receipt or
                                                                    Deposit
                                                                    Challan copy
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <Field name="challan_copy">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="challan_copy"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Repo Release
                                                                    Approval
                                                                    (NRM)
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <Field name="approval_nrm">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="approval_nrm"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Payment Receipting Detail
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="table-responsive text-nowrap">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Sr no
                                                                    </th>
                                                                    <th>
                                                                        LAN no
                                                                    </th>
                                                                    <th>
                                                                        Receipt
                                                                        date
                                                                    </th>
                                                                    <th>
                                                                        UNO
                                                                        Receipt
                                                                        No
                                                                    </th>
                                                                    <th>
                                                                        Receipt
                                                                        amount
                                                                    </th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paymentDetailsArr.map(
                                                                    (
                                                                        details: PaymentDetails,
                                                                        index: number
                                                                    ) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {index +
                                                                                        1}

                                                                                    .
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        details.lan_no
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <span className="badge bg-label-primary me-1">
                                                                                        {formatDateToDDMMYYYY(
                                                                                            details.receipt_date
                                                                                        )}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        details.uno_receipt_no
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    ₹
                                                                                    {
                                                                                        details.receipt_amount
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-icon btn-danger"
                                                                                        onClick={() => {
                                                                                            const updatedDetails =
                                                                                                [
                                                                                                    ...paymentDetailsArr,
                                                                                                ];
                                                                                            updatedDetails.splice(
                                                                                                index,
                                                                                                1
                                                                                            );
                                                                                            setPaymentDetailsArr(
                                                                                                updatedDetails
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="ti ti-x"></span>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-primary mt-2"
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        onClick={() => {
                                                            setLanNo(
                                                                form.getState()
                                                                    .values
                                                                    .lan_no
                                                            );
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        <span className="ti ti-plus"></span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total Receipt Amount
                                                </label>
                                                <div
                                                    className="col-sm-9"
                                                    style={{
                                                        justifyContent:
                                                            "center",
                                                        display: "flex",
                                                        flex: "1",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div className="input-group input-group-merge">
                                                        ₹ {totalReceiptAmount}
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                {repoId && (
                    <>
                        <h4 className="py-3 mb-1">Logs</h4>
                        <div className="row">
                            {logs.map((log: RepoReleaseLogs) => {
                                return (
                                    <div className="col-md-3 col-sm-6 col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h6 className="mb-0">
                                                    {formatDateToDDMMYYYYWithTime(
                                                        log.createdAt
                                                    )}
                                                </h6>
                                                <p className="mb-0">
                                                    {log?.uid?.name}
                                                </p>
                                                <p className="mb-0">
                                                    {getStatusText(log?.status)}
                                                </p>
                                                <p className="mb-0">
                                                    {log.remarks}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default AddRepoRelease;
