import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import Repo from '../../interfaces/repo';
import ErrorResponse from '../../interfaces/ErrorResponse';
import { RepoLogs } from '../../interfaces/repo_logs';

export interface RepoState {
  items: Repo[];
  exportItems: Repo[];
  loading: boolean | null;
  error: string | null;
  repo: Repo,
  added: boolean | null;
  logs: RepoLogs[];
  remarksUpdated: boolean | null;
}

interface searchParams{
  _id?: String
}

interface statusParams{
  status: 'approved' | 'rejected',
  _id: string,
  remarks?:string 
}

interface fetchRepoParams{
  regions?: string,
  page?: number,
  export?: boolean,
  search?: string,
}

export const fetchRepos = createAsyncThunk('/fetchRepos', async (params: fetchRepoParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo', {params: params});
    return response.data as Repo[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const searchRepos = createAsyncThunk('/searchRepos', async (params: fetchRepoParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo', {params: params});
    return response.data as Repo[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchExportRepos = createAsyncThunk('/fetchExportRepos', async (params: fetchRepoParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo', {params: params});
    return response.data as Repo[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleRepo = createAsyncThunk('/fetchSingleRepo', async (params:searchParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo',{params:params});
    return response.data as Repo[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addRepo = createAsyncThunk('/addRepo', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/repo', formData);
    return response.data as Repo;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateRepo = createAsyncThunk('/updateRepo', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/repo/update', formData);
    return response.data as Repo;
  } catch (error) {
    console.log(error);
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateRepoStatus = createAsyncThunk('/updateRepoStatus', async (values:statusParams, { rejectWithValue }) => {
  try {
    const response = await api.put('/repo/update-status', values);
    return response.data as Repo;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchRepoLogs = createAsyncThunk('/fetchRepoLogs', async (id:string, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo/logs/' + id);
    return response.data as RepoLogs[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const editRemarks = createAsyncThunk('/editRemarks', async (values:any, { rejectWithValue }) => {
  try {
    const response = await api.post('/repo/edit-remarks/',values);
    return response.data as any;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

const repoSlice = createSlice({
  name: 'repo',
  initialState: {
    items: [],
    loading: null,
    error: null,
    repo: {} as Repo,
    added: null,
    logs: [],
    remarksUpdated: null,
    exportItems: []
  } as RepoState,
  reducers: {
    setRepo: (state,action) =>{
      state.repo = action.payload;
    },
    setRemarksUpdated: (state,action) =>{
      state.remarksUpdated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepos.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRepos.fulfilled, (state, action) => {
        // state.loading = false;
        // state.items = action.payload;
        state.loading = false;
        // if (action.meta.arg.page && action.meta.arg.page > 1) {
        //   state.items.push(...action.payload);
        // } else {
          state.items = action.payload;
        // }
      })
      // .addCase(searchRepos.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.items = action.payload;
      // })
      .addCase(fetchRepos.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(fetchExportRepos.fulfilled, (state, action) => {
        state.exportItems = action.payload;
      })
      .addCase(addRepo.fulfilled, (state, action)=>{
        state.items.unshift(action.payload);
      }).addCase(addRepo.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      }).addCase(fetchSingleRepo.fulfilled, (state, action)=>{
        state.repo = action.payload[0];
      }).addCase(updateRepoStatus.fulfilled, (state,action)=>{
        let updatedRepo = action.payload;
        state.items = state.items.map((repo: Repo) =>
          repo._id === updatedRepo._id ? updatedRepo : repo
        );
      }).addCase(updateRepo.fulfilled, (state,action)=>{
        let updatedRepo = action.payload;
        state.items = state.items.map((repo: Repo) =>
          repo._id === updatedRepo._id ? updatedRepo : repo
        );
      }).addCase(fetchRepoLogs.fulfilled, (state,action)=>{
        state.logs = action.payload;
      })
      .addCase(editRemarks.fulfilled, (state,action)=>{
        state.remarksUpdated = true;
      })
  },
});

export const { setRepo, setRemarksUpdated } = repoSlice.actions;

export const getRepoLoading = (state:{ repo: RepoState }) => state.repo.loading;
export const getRepoAdded = (state:{ repo: RepoState }) => state.repo.added;
export const getRepoItems = (state:{ repo: RepoState }) => state.repo.items;
export default repoSlice.reducer;
