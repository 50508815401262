import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateToDDMMYYYY } from "../../helpers/date";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";
import { LocalBid } from "../../interfaces/local_bid";
import {
    fetchSingleLocalBid,
    updateLocalBidStatus,
} from "../../redux/reducers/local_bid";

const ViewLocalBid: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { bidId } = useParams();
    const navigate = useNavigate();
    let localBid: LocalBid = useSelector(
        (state: any) => state.localBid.localBid
    );
    const userData: User = useSelector((state: any) => state.user.userData);

    useEffect(() => {
        dispatch(fetchSingleLocalBid({ _id: bidId }));
    }, [bidId]);

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    const getEMDMode = (mode: string) => {
        if (mode == "demand_draft") {
            return "Demand Draft";
        } else if (mode == "online") {
            return "Online";
        }
        return mode;
    };

    return (
        <div className="row">
            <div className="col-xxl">
                <div className="card mb-4">
                    <div className="card-body">
                        <form>
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="basic-default-name"
                                >
                                    LAN No :
                                </label>
                                <div className="col-sm-8">
                                    <div className="d-flex justify-content-start align-items-center mt-1">
                                        <div className="me-2">
                                            <i className="ti ti-hash"></i>
                                        </div>
                                        <p className="mb-0 fw-normal">
                                            {localBid.lan_no}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="basic-default-name"
                                >
                                    RC No :
                                </label>
                                <div className="col-sm-8">
                                    <div className="d-flex justify-content-start align-items-center mt-1">
                                        <div className="me-2">
                                            <i className="ti ti-certificate"></i>
                                        </div>
                                        <p className="mb-0 fw-normal">
                                            {localBid.rc_no}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="basic-default-name"
                                >
                                    Zone :
                                </label>
                                <div className="col-sm-8">
                                    <div className="d-flex justify-content-start align-items-center mt-1">
                                        <div className="me-2">
                                            <i className="ti ti-certificate"></i>
                                        </div>
                                        <p className="mb-0 fw-normal">
                                            {localBid.zone}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="basic-default-name"
                                >
                                    Region :
                                </label>
                                <div className="col-sm-8">
                                    <div className="d-flex justify-content-start align-items-center mt-1">
                                        <div className="me-2">
                                            <i className="ti ti-certificate"></i>
                                        </div>
                                        <p className="mb-0 fw-normal">
                                            {localBid.region}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="basic-default-name"
                                >
                                    Area :
                                </label>
                                <div className="col-sm-8">
                                    <div className="d-flex justify-content-start align-items-center mt-1">
                                        <div className="me-2">
                                            <i className="ti ti-certificate"></i>
                                        </div>
                                        <p className="mb-0 fw-normal">
                                            {localBid.area}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="basic-default-name"
                                >
                                    Branch :
                                </label>
                                <div className="col-sm-8">
                                    <div className="d-flex justify-content-start align-items-center mt-1">
                                        <div className="me-2">
                                            <i className="ti ti-certificate"></i>
                                        </div>
                                        <p className="mb-0 fw-normal">
                                            {localBid.branch}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <hr className="my-4 mx-n4" />
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="multicol-birthdate"
                                >
                                    Local Bid
                                </label>

                                <div className="col-sm-8">
                                    <div className="table-responsive text-nowrap">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Particulars</th>
                                                    <th>Information</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Bidder Name</td>
                                                    <td>
                                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                                            <div className="me-2">
                                                                <i className="ti ti-user"></i>
                                                            </div>
                                                            <p className="mb-0 fw-normal">
                                                                {
                                                                    localBid.bidder_name
                                                                }
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Bidder Contact No</td>
                                                    <td>
                                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                                            <div className="me-2">
                                                                <i className="ti ti-phone"></i>
                                                            </div>
                                                            <p className="mb-0 fw-normal">
                                                                {
                                                                    localBid.bidder_contact
                                                                }
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Mode Of EMD</td>
                                                    <td>
                                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                                            <div className="me-2">
                                                                {/* <i className="ti ti-phone"></i> */}
                                                            </div>
                                                            <p className="mb-0 fw-normal">
                                                                {getEMDMode(
                                                                    localBid.emd_mode
                                                                )}
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Bidder PAN Number</td>
                                                    <td>
                                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                                            <div className="me-2">
                                                                {/* <i className="ti ti-phone"></i> */}
                                                            </div>
                                                            <p className="mb-0 fw-normal">
                                                                {
                                                                    localBid.bidder_pan
                                                                }
                                                            </p>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Bid Amount</td>
                                                    <td>
                                                        ₹ {localBid.bid_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>EMD Amount</td>
                                                    <td>
                                                        ₹ {localBid.emd_amount}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        EMD Details (Payment
                                                        Reference Number)
                                                    </td>
                                                    <td>
                                                        {localBid.emd_details}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Proposed Employee Code
                                                    </td>
                                                    <td>
                                                        {
                                                            localBid.proposed_employee_code
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Proposed Employee Name
                                                    </td>
                                                    <td>
                                                        {
                                                            localBid.proposed_employee_name
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Department</td>
                                                    <td>
                                                        {localBid.department}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Remarks</td>
                                                    <td>{localBid.remarks}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-4 mx-n4" />
                            <div className="row mb-3">
                                <label
                                    className="col-sm-4 col-form-label text-sm-end text-primary"
                                    htmlFor="multicol-birthdate"
                                ></label>
                                <div className="col-sm-8">
                                    <div className="row row-gap-4">
                                        <div className="col-md-4">
                                            <a
                                                href={
                                                    localBid.quotation as string
                                                }
                                                target="_blank"
                                                className="d-flex align-items-center"
                                            >
                                                <div className="badge bg-label-primary p-2 me-3 rounded">
                                                    <i className="ti ti-file-text ti-sm"></i>
                                                </div>
                                                <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                    <div className="me-2">
                                                        <h6 className="mb-0">
                                                            Uploaded Quotation
                                                        </h6>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {userData?.role?.rights.includes(
                                "approve_local_bid"
                            ) &&
                                localBid.status?.indexOf("approved_l2") < 0 && (
                                    <div className="row justify-content-end">
                                        <div className="col-sm-8">
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    dispatch(
                                                        updateLocalBidStatus({
                                                            status: "approved",
                                                            _id: bidId as string,
                                                        })
                                                    );

                                                    navigate(
                                                        "/local-bid-listing"
                                                    );
                                                }}
                                            >
                                                ACCEPT
                                            </button>
                                        </div>
                                    </div>
                                )}
                            <hr className="my-4 mx-n4" />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewLocalBid;
