import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";
import LegalRequest from "../../interfaces/legal_request";
import {
    fetchSingleLegalRequest,
    uploadLegalDocument,
} from "../../redux/reducers/legal-request";
import {
    legalRequestAttachmentLov,
    legalRequestLov,
} from "../../helpers/constants";

const ViewLegalRequest: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [remark, setRemark] = useState("");
    const [document, setDocument] = useState("");
    const { legalId } = useParams();
    const navigate = useNavigate();
    let legalRequest: LegalRequest = useSelector(
        (state: any) => state.legalRequest.legalRequest
    );
    const documentAdded: boolean = useSelector(
        (state: any) => state.legalRequest.documentAdded
    );
    const userData: User = useSelector((state: any) => state.user.userData);

    useEffect(() => {
        dispatch(fetchSingleLegalRequest({ _id: legalId }));
    }, [legalId]);

    useEffect(() => {
        if (documentAdded) {
            window.location.reload();
        }
    }, [documentAdded]);

    return (
        <div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Documents</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="file"
                            className="form-control"
                            placeholder="Document"
                            onChange={(event: any) => {
                                setDocument(event.target.files[0]);
                            }}
                        />
                        <br />
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            onChange={(event: any) => {
                                setRemark(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (remark.trim().length > 0 && document) {
                                    const formData = new FormData();
                                    formData.append("_id", legalId as string);
                                    formData.append("document", document);
                                    formData.append("approverRemarks", remark);
                                    dispatch(uploadLegalDocument(formData));
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">Legal Request Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Borrower Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.borrower_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Borrower Address :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.borrower_address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Borrower Contact :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.borrower_contact}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Gurantor Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.gurantor_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Gurantor Address :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.gurantor_address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Gurantor Contact :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.gurantor_contact}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Vehicle Availability :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {
                                                    legalRequest.vehicle_availability
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Legal Request :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.legal_request}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Priority as per OPN POS :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.priority}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Remarks :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.remarks}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Created At :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYYWithTime(
                                                    legalRequest.createdAt
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Created By :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.createdBy?.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {legalRequest?.document && (
                                    <div className="row mb-3">
                                        <label
                                            className="col-sm-4 col-form-label text-sm-end text-primary"
                                            htmlFor="basic-default-name"
                                        >
                                            Document
                                        </label>
                                        <div className="col-sm-8">
                                            <div className="d-flex justify-content-start align-items-center mt-1">
                                                <a
                                                    href={
                                                        legalRequest.document as string
                                                    }
                                                >
                                                    Open Document
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {legalRequest?.approverRemarks && (
                                    <div className="row mb-3">
                                        <label
                                            className="col-sm-4 col-form-label text-sm-end text-primary"
                                            htmlFor="basic-default-name"
                                        >
                                            Approver Remarks
                                        </label>
                                        <div className="col-sm-8">
                                            <div className="d-flex justify-content-start align-items-center mt-1">
                                                {legalRequest.approverRemarks}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {userData?.role?.name == "l2" &&
                                    legalRequestAttachmentLov().includes(
                                        legalRequest.legal_request
                                    ) &&
                                    !legalRequest.document &&
                                    !legalRequest.approverRemarks && (
                                        <div className="row mb-3">
                                            <label
                                                className="col-sm-4 col-form-label text-sm-end text-primary"
                                                htmlFor="basic-default-name"
                                            >
                                                Upload Documents
                                            </label>
                                            <div className="col-sm-8">
                                                <div className="d-flex justify-content-start align-items-center mt-1">
                                                    <button
                                                        className="btn btn-primary"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        Upload
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewLegalRequest;
