import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import LegalRequest from '../../interfaces/legal_request';

export interface LegalRequestState {
  items: LegalRequest[];
  loading: boolean | null;
  error: string | null;
  legalRequest: LegalRequest,
  added: boolean | null;
  documentAdded: boolean | null
}

interface searchParams{
  _id?: String
}

interface statusParams{
  status: 'approved' | 'rejected',
  _id: string,
  remarks?:string 
}

interface fetchLegalRequestParams{
  regions?: string,
  export?:boolean,
  search?:string
}

export const fetchLegalRequest = createAsyncThunk('/fetchLegalRequest', async (params: fetchLegalRequestParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/legal-request', {params: params});
    if(params.export){
      downloadCSV(response.data);
    }
    else{
      return response.data as LegalRequest[];
    }
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleLegalRequest = createAsyncThunk('/fetchSingleLegalRequest', async (params:searchParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/legal-request',{params:params});
    return response.data as LegalRequest[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addLegalRequest = createAsyncThunk('/addLegalRequest', async (values:any, { rejectWithValue }) => {
  try {
    const response = await api.post('/legal-request', values);
    return response.data as LegalRequest;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateLegalRequestStatus = createAsyncThunk('/updateLegalRequestStatus', async (values:statusParams, { rejectWithValue }) => {
  try {
    const response = await api.put('/legal-request/update-status', values);
    return response.data as LegalRequest;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const uploadLegalDocument = createAsyncThunk('/uploadLegalDocument', async (values:any, { rejectWithValue }) => {
  try {
    const response = await api.post('/legal-request/upload-document', values);
    return response.data as any;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

function downloadCSV(csvContent:any) {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Legal Request.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

const legalRequestSlice = createSlice({
  name: 'legal-request',
  initialState: {
    items: [],
    loading: null,
    error: null,
    legalRequest: {} as LegalRequest,
    added: null,
    documentAdded: null
  } as LegalRequestState,
  reducers: {
    setLegalRequest: (state,action) =>{
      state.legalRequest = action.payload;
    },
    setDocumentAdded:(state,action) =>{
      state.documentAdded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLegalRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLegalRequest.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload){
          state.items = action.payload;
        }
      })
      .addCase(fetchLegalRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(addLegalRequest.fulfilled, (state, action)=>{
        state.items.unshift(action.payload);
      }).addCase(addLegalRequest.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      }).addCase(fetchSingleLegalRequest.fulfilled, (state, action)=>{
        state.legalRequest = action.payload[0];
      }).addCase(updateLegalRequestStatus.fulfilled, (state,action)=>{
        let updatedLegalRequest = action.payload;
        state.items = state.items.map((repo: LegalRequest) =>
          repo._id === updatedLegalRequest._id ? updatedLegalRequest : repo
        );
      }).addCase(uploadLegalDocument.fulfilled, (state,action)=>{
          state.documentAdded = true;
      })
  },
});

export const { setLegalRequest } = legalRequestSlice.actions;
export default legalRequestSlice.reducer;