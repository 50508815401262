import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import OtsClosure from '../../interfaces/ots_closure';
import { OtsClosureLogs } from '../../interfaces/ots_closure_logs';
import OtsApproval from '../../interfaces/ots_approval';

export interface OtsClosureState {
  items: OtsClosure[];
  loading: boolean | null;
  error: string | null;
  otsClosure: OtsClosure,
  added: boolean | null;
  logs: OtsClosureLogs[];
  otsApprovalRecord: OtsApproval;
}

interface searchParams{
  _id?: String
}

interface statusParams{
  status: 'approved' | 'rejected' | 'accepted',
  _id: string,
  remarks?:string 
}

interface fetchOtsClosureParams{
  regions?: string,
  export?:boolean,
  search?:string
}

export const fetchOtsClosure = createAsyncThunk('/fetchOtsClosure', async (params: fetchOtsClosureParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-closure', {params: params});
    if(params.export){
      downloadCSV(response.data);
    }
    else{
      return response.data as OtsClosure[];
    }
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleOtsClosure = createAsyncThunk('/fetchSingleOtsClosure', async (params:searchParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-closure',{params:params});
    return response.data as OtsClosure[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addOtsClosure = createAsyncThunk('/addOtsClosure', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
        } 
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/ots-closure', formData);
    return response.data as OtsClosure;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateOtsClosure = createAsyncThunk('/updateOtsClosure', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        }
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/ots-closure/update', formData);
    return response.data as OtsClosure;
  } catch (error) {
    console.log(error);
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateOtsClosureStatus = createAsyncThunk('/updateOtsClosureStatus', async (values:statusParams, { rejectWithValue }) => {
  try {
    const response = await api.put('/ots-closure/update-status', values);
    return response.data as OtsClosure;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchOtsClosureLogs = createAsyncThunk('/fetchOtsClosureLogs', async (id:string, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-closure/logs/' + id);
    return response.data as OtsClosureLogs[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

interface OtsApprovalRecordParams {
  lan_no?:string
}

export const getOtsApprovalRecord = createAsyncThunk('/getOtsApprovalRecord', async (params:OtsApprovalRecordParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-closure/ots-approval-record', {params:params});
    return response.data as OtsApproval;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

function downloadCSV(csvContent:any) {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'OTS Closure.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}


const otsClosureSlice = createSlice({
  name: 'ots-closure',
  initialState: {
    items: [],
    loading: null,
    error: null,
    otsClosure: {} as OtsClosure,
    otsApprovalRecord: {} as OtsApproval,
    added: null,
    logs: [],
    remarksUpdated: null
  } as OtsClosureState,
  reducers: {
    setOtsClosure: (state,action) =>{
      state.otsClosure = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOtsClosure.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOtsClosure.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload){
          state.items = action.payload;
        }
      })
      .addCase(fetchOtsClosure.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(addOtsClosure.fulfilled, (state, action)=>{
        state.items.unshift(action.payload);
      }).addCase(addOtsClosure.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      }).addCase(fetchSingleOtsClosure.fulfilled, (state, action)=>{
        state.otsClosure = action.payload[0];
      }).addCase(updateOtsClosureStatus.fulfilled, (state,action)=>{
        let updatedOtsClosure = action.payload;
        state.items = state.items.map((otsClosure: OtsClosure) =>
          otsClosure._id === updatedOtsClosure._id ? updatedOtsClosure : otsClosure
        );
      }).addCase(updateOtsClosure.fulfilled, (state,action)=>{
        let updatedOtsClosure = action.payload;
        state.items = state.items.map((otsClosure: OtsClosure) =>
          otsClosure._id === updatedOtsClosure._id ? updatedOtsClosure : otsClosure
        );
      }).addCase(fetchOtsClosureLogs.fulfilled, (state,action)=>{
        state.logs = action.payload;
      }).addCase(getOtsApprovalRecord.fulfilled, (state,action)=>{
        state.otsApprovalRecord = action.payload;
      }).addCase(getOtsApprovalRecord.rejected, (state,action)=>{
        alert((action.payload as any)?.message);
        window.history.back();
      });
  },
});

export const { setOtsClosure } = otsClosureSlice.actions;
export default otsClosureSlice.reducer;